import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import router from '@/router';
import { title } from '@core/utils/filter';
import { format } from 'date-fns';
import arDZ from 'date-fns/locale/ar-DZ';
// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useQuotationList() {
  // Use toast
  const toast = useToast();

  const refQuotationListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: 'reference',
      label: 'رقم عرض السعر',
      formatter: title,
      sortable: true,
    },
    {
      key: 'client.name',
      label: 'اسم العميل',
      formatter: title,
      sortable: true,
    },
    {
      key: 'created_at',
      label: 'تاريخ الطلب',
      formatter: (value) => format(new Date(value), 'dd MMMM yyyy', { locale: arDZ }),
      sortable: true,
    },
    {
      key: 'city.name_ar',
      label: 'المدينة',
      formatter: title,
      sortable: true,
    },
    {
      key: 'estate_count',
      label: 'عدد العقارات',
      formatter: title,
      sortable: true,
    },
    {
      key: 'total_cost',
      label: 'إجمالي الأتعاب',
      formatter: title,
      sortable: true,
    },
    {
      key: 'status',
      label: 'الحالة',
      formatter: title,
      sortable: true,
    },
    { key: 'actions', label: 'الإجراءات' },
  ];
  const perPage = ref(10);
  const totalQuotation = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  const statusFilter = ref(null);
  const dataMeta = computed(() => {
    const localItemsCount = refQuotationListTable.value ? refQuotationListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalQuotation.value,
    };
  });

  const refetchData = () => {
    refQuotationListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData();
  });

  const fetchQuotations = (ctx, callback) => {
    store
      .dispatch('quotation/fetchQuotations', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then((response) => {
        const { data: quotations, total } = response.data;

        callback(quotations);
        totalQuotation.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'حدث خطأ عند جلب القائمة',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };
  const fetchClientQuotations = (ctx, callback) => {
    store
      .dispatch('quotation/fetchQuotations', {
        client_id: router.currentRoute.params.id || null,
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then((response) => {
        const { data: quotations, total } = response.data;

        callback(quotations);
        totalQuotation.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'حدث خطأ عند جلب القائمة',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchQuotations,
    fetchClientQuotations,
    tableColumns,
    perPage,
    currentPage,
    totalQuotation,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refQuotationListTable,
    refetchData,

    // Extra Filters
    statusFilter,
  };
}
