import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchQuotations(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/quotation', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchQuotation(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/quotation/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateQuotation(ctx, { quotationData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/quotation/${id}`, quotationData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateStatus(ctx, { id, newStatus , notes = null}) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/quotation/${id}/updateStatus`, { status: newStatus , notes:notes })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    deleteQuotation(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/quotation/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addQuotation(ctx, quotationData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/quotation', quotationData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClients(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/client/list')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCities(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/city/list')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchQuotationOptions(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/quotation/options')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDistricts(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/district/city/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    downloadQuotation(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/quotation/${id}/download`, {
            responseType: 'blob',
            headers: {
              Accept: 'application/pdf',
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
